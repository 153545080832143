.tab {
    padding: 0 20px;
    cursor: pointer;
    color:black!important;

  }
  
  .active-tab {
    background-color: #4a90e2; /* You can adjust the color */
    color: white!important;
    border-radius: 5px 5px 0px 0px;
  }