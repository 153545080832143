.page{
    padding: 16px;
}
/* Add a CSS class to hide elements outside the modal during printing */
@media print {
    body * {
      visibility: hidden;

      
    }
    .page *{
      display: hidden;
      height: 0;
    }
    
    /* Display only the modal and its contents during printing */
    .modal-container, .modal-container * {
      visibility: visible;

    }
 
  }

  
  