.title-2{
  font-weight: 500;
  font-size: 1.25rem;

}
.bg-custom{
  background-color: rgb(180, 219, 255);
}
.bg-suspect{
  background-color: #A1EACD;
}
.bg-prospect{
  background-color: #FFF1B0;
}
.bg-activation{
  background-color: #FFD894
}
.bg-proposal{
  background-color: #FF7F50;
}
.bg-sleep{
  background-color: #bbbbbb;
}
.bg-client{
  background-color: #acb1f7;
}
.bg-total{
  background-color: rgb(137, 183, 253);
}

.divider {
  border-right: 1px solid #bbb; /* Adjust border color and style as needed */
  margin: 0 4px; /* Adjust margin as needed */


  align-self: center;
 
}

